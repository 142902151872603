import languageSDK from "../languages";

export default class Helpers {
    static loading = {
        drawLoadingSpinner: ()=>{
            const canvas = document.getElementById('loadingCanvas');
            canvas.style.display = 'block'; // Hiển thị canvas nếu nó đang ẩn


            const ctx = canvas.getContext('2d');
            let startAngle = 0; // Bắt đầu của spinner
        
            const draw = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height); // Xóa canvas
                ctx.beginPath();
                ctx.arc(25, 25, 20, startAngle, startAngle + Math.PI * 1.5); // Vẽ một cung tròn
                ctx.strokeStyle = '#007BFF'; // Màu của spinner
                ctx.lineWidth = 4; // Độ dày của đường viền
                ctx.stroke(); // Vẽ đường viền
                startAngle += 0.1; // Cập nhật góc bắt đầu để spinner quay
                requestAnimationFrame(draw); // Gọi lại hàm draw để tạo animation
            };
        
            draw(); // Bắt đầu vẽ spinner
        },
        hideLoadingSpinner:()=>{
            const canvas = document.getElementById('loadingCanvas');
            canvas.style.display = 'none'; // Ẩn canvas khi không cần nữa
        }
    }
    static errorByCode(code) {
        let message;
        switch (code) {
            case 2: case 15: case 26: case 29: case 30: case 36: case 38: case 42: case 56: case 57: case 58: case 59: case 60:
                message = languageSDK.language.trueID_input_image_is_invalid ;  // Replace with localization if needed
                break;
            case 33: case 39: case 40: case 43: case 44: case 45: case 46: case 47: case 48: case 55:
                message = languageSDK.language.trueID_invalid_id_text_fields ; //"true_invalid_id_text_fields";  // Replace with localization if needed
                break;
            case 4:
                message = languageSDK.language.trueID_card_in_image_too_small //"true_image_too_small";
                break;
            case 14:
                message = languageSDK.language.trueID_missing_corner;
                break;
            case 23:
                message = languageSDK.language.trueID_image_overexposed;
                break;
            case 27:
                message =  languageSDK.language.trueID_image_blurry;
                break;
            case 28:
                message = languageSDK.language.trueID_image_too_dark;
                break;
            case 31:
                message = languageSDK.language.trueID_image_too_small;
                break;
            case 17:
                message = languageSDK.language.trueID_face_not_matched;
                break;
            case 41:
                message = languageSDK.language.trueID_duplicated_id;
                break;
            case 51: case 52:
                message =  languageSDK.language.trueID_duplicated_face;
                break;
            case 53: case 54:
                message = languageSDK.language.trueID_blacklisted;
                break;
            case 10:
                message = languageSDK.language.trueID_spam_image;
                break;
            case 65:
                console.log("anguageSDK.language.trueID_obscured", languageSDK.language.trueID_obscured)
                message = languageSDK.language.trueID_obscured;
                break;
            case 18: case 25: case 24: case 34: case 50: case 66:
                message = languageSDK.language.trueID_invalid_selfie;
                break;
            case -1:
                message = "Something error";
                break;
            case 0:
                message = "TrueID SDK close";
                break;
            case 100:
                message = "Facetec liveness: Locked out";
                break;
            case 101:
                message = "NFC error";
                break;
            case 102:
                message = "NFC not support";
                break;
            case 301:
                message = "Liveness timeout";
                break;
            case 302:
                message = "Trueid Locked";
                break;
            case 303:
                message = "Simulator cannot start ekyc Sdk";
                break;
            case 304:
                message = "Error backgound mode";
                break;
            default:
                message = "Something error";
        }
    
        return { code, message };
    }

}
export const screenIDState = {
    START:0,
    CALLING_API:1,
    CALL_API_FAILED:2,
    UPDATE_SUCCESS:3,
}

export const screenSelfieState = {
    START:{
        value: 0,
        message: "Chụp hình selfie"
    },
    FACE_DETECT_DEFAULT:{
        value:4,
        message: "Đưa khuôn mặt vào khung hình"
    },
    FACE_DETECT_SMALL:{
        value:5,
        message: "Đưa khuôn lại gần"
    },
    FACE_DETECT_LARGE:{
        value:6,
        message: "Đưa khuôn ra xa"
    },
    FACE_DETECT_OK:{
        value:7,
        message: "Giữ ổn định"
    },
    CALLING_API:{
        value: -2,
        message: "Đang kiểm tra"
    },
    CALL_API_FAILED:{
        value: -3,
        message: "Xác thực thất bại"
    },
    UPDATE_SUCCESS:{
        value: -4,
        message: "Xác thực thành công"
    },
}

